<!--
 * @Description: 运维管理 运维管理 运维工单管理 绑定设备清单 operateUnitDev
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-08-16 11:51:47
 * @LastEditors: zhoucheng
-->
<template>
  <div class="operateUnitDev-mainBody">
    <el-row class="topBack"
            style="margin-bottom:10px">
      <i class="el-icon-back"
         @click="handleClickBack"></i>
      <span @click="handleClickBack"> 返 回</span>
    </el-row>
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm"
               class="searchArea">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="110px"
                          label="设备编号">
              <el-input v-model="searchForm.deviceId"
                        placeholder="请输入设备编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="110px"
                          label="设备名称">
              <el-input v-model="searchForm.deviceName"
                        placeholder="请输入设备名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="110px"
                          label="设备地址">
              <el-input v-model="searchForm.address"
                        placeholder="请输入设备地址"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topSecondLine">
          <el-col :span="6">
            <el-row class="topButton">
              <el-button type="primary"
                         @click="handleClickSearch">查 询</el-button>
              <el-button type="info"
                         style="margin-left:17px"
                         @click="handleClickReset">重 置</el-button>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>设备绑定清单</span>
        <el-row class="topButton tableTitleButton">
          <el-button type="success"
                     @click="handleClickAdd">新 增</el-button>
          <!-- <el-button type="info"
                     style="margin-left:17px">导入</el-button>
          <el-button type="info"
                     style="margin-left:17px">导 出</el-button>
          <el-button type="danger"
                     style="margin-left:17px">批量删除</el-button> -->
        </el-row>
      </el-row>
      <el-row class="tableContent">
        <el-tabs v-model="activeName"
                 @tab-click="handleClickTab">
          <el-tab-pane v-for="(item, index) in deviceList"
                       :label="item.name"
                       :value="item.code"
                       :key="index">
            <dt-table :tableColumnList="tableColumnList"
                      :data="tableList"
                      :map="listMap"
                      :tableConfig='tableConfig'
                      :config="tableConfig"
                      :paginationConfig='paginationConfig'
                      @getList="getList">
              <template slot="operating">
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button @click="handleClickRelieve(scope.row)"
                               type="text"
                               size="medium">解除运维关系</el-button>
                  </template>
                </el-table-column>
              </template>
            </dt-table>
          </el-tab-pane>
        </el-tabs>
      </el-row>
      <el-row>
        <!-- 新增 -->
        <el-dialog title="新增关系"
                   :visible.sync="addDialogVisible"
                   append-to-body
                   @close="popClose">
          <el-form label-width="150px"
                   :model="addForm"
                   :rules="rules"
                   ref="addForm"
                   size="small">
            <el-row justify="space-around">
              <el-col :span="12">
                <el-form-item label="设备类型"
                              prop="deviceType">
                  <el-select v-model="addForm.deviceType"
                             placeholder="请选择"
                             @change="handleChangeDeviceType">
                    <el-option v-for="item in deviceTypeAddList"
                               :label="item.name"
                               :value="item.code"
                               :key="item.code">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="设备编号"
                              prop="deviceId">
                  <el-select v-model="addForm.deviceId"
                             placeholder="请选择">
                    <el-option v-for="(item,index) in deviceAddList"
                               :label="item.name"
                               :value="item.code"
                               :key="index">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex"
                    justify="end">
              <el-col :span="12"
                      class="dt-button-box">
                <el-button type="primary"
                           @click="handleClickAddConfirm()"
                           style="margin-left:17px;
                     border-radius:4px;
                     height:36px;">确 定</el-button>
                <el-button @click="addDialogVisible = false"
                           type="info">取 消</el-button>
              </el-col>
            </el-row>
          </el-form>
        </el-dialog>
      </el-row>
    </el-row>
  </div>
</template>

<script>
import dtTable from '@/components/table.vue'
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data () {
    return {
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableConfig: { border: true },
      listMap: {
        pageNum: 1,
        pageSize: 15,
      },
      tableColumnList: [
        {
          prop: 'deviceId',
          label: '设备编号',
        },
        {
          prop: 'deviceName',
          label: '设备名称',
        },
        {
          prop: 'address',
          label: '设备地址',
        },
        {
          prop: 'deviceType',
          label: '设备类型',
        },
        {
          prop: 'bindTime',
          label: '运维绑定时间',
        }
      ],
      tableList: {
        list: [],
      },
      // 搜索表单
      searchForm: {
        deviceId: "",
        deviceName: "",
        address: ""
      },
      pageNum: 1,
      pageSize: 15, // 初始化分页
      maintenanceId: "", // 运维单位ID
      deviceType: "",// 设备类型
      activeName: '0',// tab页显示第一个
      deviceList: [], // 管理设备类型
      addDialogVisible: false,//新增弹窗显示
      addForm: {
        deviceType: "",
        deviceId: ""
      },//新增表单
      deviceTypeAddList: [], // 设备类型
      deviceAddList: [], // 设备类型
      rules: {
        deviceId: [{ required: true, message: '请选择活动区域', trigger: 'change' }],
        deviceType: [{ required: true, message: '请选择活动区域', trigger: 'change' }],
      },
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.maintenanceId = this.$route.query.maintenanceId
    this.queryTypeList()
    this.queryDeviceTypeList()
  },
  //方法集合
  methods: {
    // 查询该单位管理了多少设备
    queryDeviceTypeList () {
      let info = {
        maintenanceId: this.$route.query.maintenanceId
      }
      this.$operateUnitDev.queryDeviceTypeList(info).then(res => {
        this.deviceList = res.resultEntity
        // 默认展示第一位 以及存储设备类型
        this.queryDeviceConfig(this.deviceList[0].code)
        this.deviceType = this.deviceList[0].code
      })
    },
    // 每种设备类型表格渲染
    queryDeviceConfig (type) {
      this.searchForm["maintenanceId"] = this.$route.query.maintenanceId
      this.searchForm["pageNum"] = this.pageNum
      this.searchForm["pageSize"] = this.pageSize
      this.searchForm["deviceType"] = type
      this.$operateUnitDev.queryDeviceConfig(this.searchForm).then(res => {
        this.tableList.list = res.resultEntity.list
        this.paginationConfig.total = res.resultEntity.total
      })
    },
    // 分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryDeviceConfig(this.deviceType)
    },
    // tab页切换事件
    handleClickTab (tab) {
      this.deviceType = tab.$attrs.value
      this.queryDeviceConfig(tab.$attrs.value)
    },
    // 查询按钮
    handleClickSearch () {
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.queryDeviceConfig(this.deviceType)
    },
    // 重置按钮
    handleClickReset () {
      this.searchForm = {}
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.queryDeviceConfig(this.deviceType)
    },
    // 新增按钮
    handleClickAdd () {
      this.addDialogVisible = true
      this.addForm = {}
    },
    // 新增确定按钮
    handleClickAddConfirm () {
      if (this.addForm.deviceId && this.addForm.deviceType) {
        this.addForm["maintenanceId"] = this.$route.query.maintenanceId
        this.$operateUnitDev.bindMaintenanceDevice(this.addForm).then(() => {
          this.$message({ message: '新增成功', type: 'success' });
          this.addDialogVisible = false
          this.queryDeviceConfig(this.deviceType)
          window.location.reload();
        }).catch(() => {
          this.$message.error('该设备已经被绑定过了')
        })
      }
      else {
        this.$message({ message: '请选择', type: 'error' });
      }
    },
    popClose () {
      this.$refs.addForm.resetFields();
    },
    // 新增中的设备类型下拉框
    handleChangeDeviceType (val) {
      let info =
      {
        "columnName": ["device_id", "device_id"],
        "tableName": "tb_" + val,
      }
      this.$queryDict.queryDict(info).then(res => {
        this.deviceAddList = res.resultEntity
      })
    },
    // 选择设备
    queryTypeList () {
      let info = {
        "columnName": ["value", "name"],
        "tableName": "tb_dict",
        "whereStr": [
          {
            "colName": "parent_id",
            "value": "9A5949F452B54BD9A96B9E978157CD1B"
          }
        ]
      }
      this.$queryDict.queryDict(info).then(res => {
        this.deviceTypeAddList = res.resultEntity
      })
    },
    // 解除运维设备
    handleClickRelieve (row) {
      this.$confirm('是否删除该摄像头?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$operateUnitDev.unBindMaintenanceDevice({ deviceId: row.deviceId }).then(() => {
          this.$message({ message: '删除成功', type: 'success' });
          this.queryDeviceConfig(this.deviceType)
          window.location.reload();
        })
      })
    },
    // 点击顶部返回按钮
    handleClickBack () {
      this.$router.go(-1)
    },
  },
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 135px;
//@import url(); 引入公共css类
// 最外层div
.operateUnitDev-mainBody {
  height: 100%;
  // 顶部查询条件
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
    }
    .buttonArea {
      display: flex;
    }
  }
  .tableRow {
    width: 100%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .topButton {
        display: flex;
        padding-left: 30px;
      }
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
.dt-form-width {
  width: 230px;
}
.title {
  font-size: 16px;
  color: #70aee8;
  line-height: 18px;
}
.content {
  font-size: 14px;
  color: #bae8ff;
  line-height: 18px;
}
.lineOne {
  margin-top: 25px;
}
.imageBox {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
</style>
